






























import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from './layouts/header.vue';
import Footer from './layouts/footer.vue';
import Contact from './layouts/contact.vue';
import Home from './views/Home.vue';
import Products from './views/Products.vue';
import Solutions from './views/Solutions.vue';
import Services from './views/Services.vue';
import Partners from './views/Partners.vue';
import Sectors from './views/Sectors.vue';
import Resources from './views/Resources.vue';
@Component({
    components: {
        'i-header': Header,
        'i-footer': Footer,
        'i-contact': Contact,
        'i-home': Home,
        'i-products': Products,
        'i-solutions': Solutions,
        'i-services': Services,
        'i-partners': Partners,
        'i-sectors': Sectors,
        'i-resources': Resources,
    },
})
export default class App extends Vue {
    fab = false;
    onScroll(e: any) {
        if (typeof window === 'undefined') return;
        const top = window.pageYOffset || e.target.scrollTop || 0;
        this.fab = top > 20;
    }
    toTop() {
        this.$vuetify.goTo(0);
    }
}
































import { Component, Prop, Vue } from 'vue-property-decorator';
// a trading name of Munland Ltd -  Company No. 07762954.
@Component({})
export default class Footer extends Vue {
    links = [
        { title: 'Home', url: '/' },
        { title: 'Developers', url: '/developers' },
        { title: 'Blog', url: '/' },
        { title: 'Contact Us', url: '/' },
    ];
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"services"}},[_c('v-container',{staticClass:"services lighten-5",attrs:{"fluid":""}},[_c('v-container',{attrs:{"grid-list-xs":""}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('div',{staticClass:"text-h2 text-uppercase font-weight-bold pb-5"},[_vm._v(" Our "),_c('span',{staticClass:"services--text"},[_vm._v("services ")])]),_c('h2',[_vm._v(" At intellocator, we offer a full spectrum of services to help you and your organisation flourish and do what you do best. ")])]),_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"3"}},[_c('v-img',{attrs:{"data-aos":"zoom-in-up","height":"150","contain":"","src":require('../assets/services.svg')}})],1)],1)],1)],1),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-carousel',{attrs:{"height":"500","hide-delimiter-background":"","show-arrows-on-hover":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}])},_vm._l((_vm.sectors),function(sector,i){return _c('v-carousel-item',{key:i},[_c('v-sheet',{staticClass:"services--text lighten-1",attrs:{"color":"white","height":"100%"}},[_c('v-row',{staticClass:"text-center fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"font-weight-bold text-uppercase text-md-h1 text-h3",attrs:{"cols":"12"}},[_vm._v(_vm._s(sector.title))]),_c('v-col',{staticClass:"text-md-h4 text-h5",attrs:{"cols":"12"}},[_vm._v(_vm._s(sector.text))]),_c('v-col',{staticClass:"text-md-h4 text-h5",attrs:{"cols":"12"}},_vm._l((sector.subs),function(item,i){return _c('v-tooltip',{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2",attrs:{"xxlarge":"","color":item.colour}},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])}),1)],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Partners extends Vue {
    partners = [
        {
            title: 'Amazon AWS',
            icon: 'mdi-aws',
            text:
                'Amazon Web Services offers reliable, scalable, and inexpensive cloud computing services.',

            colour: 'black',
        },
        {
            title: 'Google Cloud',
            icon: 'mdi-google-cloud',
            text:
                'Meet your business challenges head on with cloud computing services from Google.',

            colour: 'red',
        },
        {
            title: 'Microsoft Azure',
            icon: 'mdi-microsoft-azure',
            text:
                "Make your organisation more efficient with Microsoft Azure's open and flexible cloud computing platform.",

            colour: 'blue',
        },
    ];
}

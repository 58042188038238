

























































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Services extends Vue {
    sectors = [
        {
            title: 'Digital Transformation',
            text:
                'We improve business agility through better access and use of information, and  increase operational efficiency by streamlining processes and best practice.',
            subs: [
                {
                    icon: 'mdi-alert-decagram',
                    colour: 'light-green',
                    text: 'Gain',
                },
                { icon: 'mdi-file-code', colour: 'lime', text: 'Develop' },
                { icon: 'mdi-clipboard-flow', colour: 'yellow', text: 'Adopt' },
                {
                    icon: 'mdi-database-search',
                    colour: 'amber',
                    text: 'Decide',
                },
                {
                    icon: 'mdi-lightbulb-group-outline',
                    colour: 'orange',
                    text: 'Innovate',
                },
            ],
            colour: 'indigo',
        },
        {
            title: 'Consultancy & Advisory',
            text:
                'We help you understand and meet market demands anywhere in the world, whatever your industry or sector.',
            subs: [
                {
                    icon: 'mdi-account-group',
                    colour: 'orange',
                    text: 'Meet',
                },
                { icon: 'mdi-forum', colour: 'green', text: 'Gather' },
                {
                    icon: 'mdi-google-analytics',
                    colour: 'deep-orange',
                    text: 'Analysis',
                },

                {
                    icon: 'mdi-sign-direction',
                    colour: 'brown',
                    text: 'Recommend',
                },
                {
                    icon: 'mdi-ballot-recount-outline',
                    colour: 'blue-grey',
                    text: 'Change',
                },
                {
                    icon: 'mdi-file-code',
                    colour: 'grey',
                    text: 'Review',
                },
            ],
            colour: 'warning',
        },
        {
            title: 'Research & Development',
            text:
                ' We offer a powerful combination of profound tech expertise, mature, low-risk processes, and proven experience in a variety of business domains to turn your business idea into reality.',
            subs: [
                { icon: 'mdi-magnify', colour: 'red', text: 'Research' },
                {
                    icon: 'mdi-monitor-dashboard',
                    colour: 'pink',
                    text: 'Analyse',
                },
                { icon: 'mdi-file-code', colour: 'purple', text: 'Build' },
                { icon: 'mdi-ab-testing', colour: 'deep-purple', text: 'Test' },
                { icon: 'mdi-refresh', colour: 'indigo', text: 'Reiterate' },
            ],
            colour: 'pink darken-2',
        },

        {
            title: 'Training & Support',
            text:
                'We strive to build our clients’ capabilities by equipping them with the knowledge and know-how needed to successfully navigate change and strengthen their internal capabilities.',
            subs: [
                {
                    icon: 'mdi-face-agent',
                    colour: 'blue',
                    text: 'Dedicate',
                },
                {
                    icon: 'mdi-bell-check',
                    colour: 'light-blue',
                    text: 'Notify',
                },
                {
                    icon: 'mdi-projector-screen',
                    colour: 'cyan',
                    text: 'Present',
                },
                {
                    icon: 'mdi-account-voice',
                    colour: 'teal',
                    text: 'Help',
                },
                {
                    icon: 'mdi-account-group',
                    colour: 'green',
                    text: 'Discuss',
                },
            ],
            colour: 'deep-purple accent-4',
        },
    ];
}

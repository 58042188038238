






















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Products extends Vue {
    products = [
        // {
        //     title: 'Automate',
        //     text:
        //         'A set of powerful tools to make data manageable, accessible, and shareable.',
        //     icon: 'mdi-sitemap',
        //     colour: 'yellow',
        //     theme: 'light',
        // },
        {
            title: 'Data',
            text:
                'A set of powerful tools to make data manageable, accessible, and shareable.',
            icon: 'mdi-database',
            colour: 'orange',
            theme: 'dark',
        },
        {
            title: 'Map',
            text:
                'Smart maps to identify, locate, and visually track points of interest.',
            icon: 'mdi-map',
            colour: 'brown',
            theme: 'light',
        },
        {
            title: 'Visualise',
            text:
                'Real-time data to monitor, manage, track, analyse and display information.',
            icon: 'mdi-apps',
            colour: 'green',
            theme: 'light',
        },
        {
            title: 'Sense',
            text:
                'IoT connectivity to collect and exchange data, in order to predict events.',
            icon: 'mdi-memory',
            colour: 'red',
            theme: 'light',
        },
        {
            title: 'Engage',
            text:
                'Innoviative ways to allow citizens to be brought into the decision making process.',
            icon: 'mdi-forum',
            colour: 'light-blue',
            theme: 'light',
        },
        {
            title: 'Inform',
            text:
                ' Digital kiosks and signages strategically placed to keep citizes informed.',
            icon: 'mdi-billboard',
            colour: 'pink',
            theme: 'light',
        },
    ];
}
























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Home extends Vue {
    model: any = null;
    buttons: any = [
        {
            title: 'More people',
            colour: 'green',
        },
        {
            title: 'more traffic',
            colour: 'orange',
        },
        {
            title: 'more pullotion',
            colour: 'grey',
        },
        {
            title: 'more energy consumption',
            colour: 'red',
        },
        {
            title: 'more water usage',
            colour: 'blue',
        },
        {
            title: 'more waste',
            colour: 'brown',
        },
    ];
}




















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Resources extends Vue {
    resources = [
        {
            title: 'Playground',
            desription: 'Test drive',
            src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg',
            icon: 'mdi-monitor',
            colour: 'pink',
        },
        {
            title: 'Tools',
            src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg',
            icon: 'mdi-tools',
            colour: 'grey',
        },

        {
            title: 'Webinars',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            icon: 'mdi-video',
            colour: 'black',
        },
        {
            title: 'Blog',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            icon: 'mdi-post',
            colour: 'green',
        },
        {
            title: 'News',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            icon: 'mdi-web',
            colour: 'orange',
        },
        {
            title: 'Insights',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            icon: 'mdi-information',
            colour: 'blue',
        },
    ];
}


























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Sectors extends Vue {
    sectors = [
        {
            title: 'Construction',
            src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg',
            flex: 12,
            colour: 'red',
        },
        {
            title: 'Banking',
            src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg',
            flex: 6,
            colour: 'pink',
        },
        {
            title: 'Hospitality ',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'purple',
        },
        {
            title: 'Healthcare',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'indigo',
        },
        {
            title: 'Defence',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'blue',
        },
        {
            title: 'Education',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'cyan',
        },
        {
            title: 'Digital',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'teal',
        },
        {
            title: 'Media',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'green',
        },
        {
            title: 'Research',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'lime',
        },
        {
            title: 'Manufacturing',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'yellow',
        },
        {
            title: 'Telecoms',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'orange',
        },
        {
            title: 'Transport',
            src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
            flex: 6,
            colour: 'brown',
        },
    ];

    mounted() {
        this.sectors.sort(function (a, b) {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
    }
}

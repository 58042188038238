import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colours from 'vuetify/lib/util/colors';

// import minifyTheme from 'minify-css-string';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // options: { minifyTheme },
        themes: {
            light: {
                primary: '#51ddd3',
                secondary: '#b0bec5',
                anchor: '#8c9eff',
                intel: '#f7f6f9',
                locator: '#36324a',
                other: '#36324a',
                contact: colours.red.lighten5,
                products: colours.red,
                solutions: colours.orange,
                services: colours.green,
                partners: colours.cyan,
                sectors: colours.blue,
                resources: colours.indigo,
            },
            dark: {
                primary: '#51ddd3',
                secondary: '#b0bec5',
                anchor: '#8c9eff',
                intel: '#ff0033',
                locator: '#5b5777',
            },
        },
    },
});

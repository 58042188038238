

































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import emailjs from 'emailjs-com';
@Component({})
export default class Contact extends Vue {
    snackbar = false;

    valid = true;
    name = '';
    nameRules = [
        (v: any) => !!v || 'Name is required',
        (v: any) =>
            (v && v.length <= 10) || 'Name must be less than 10 characters',
    ];
    organisation = '';
    organisationRules = [
        (v: any) => !!v || 'Organisation is required',
        (v: any) =>
            (v && v.length <= 10) ||
            'Organisation must be less than 10 characters',
    ];
    email = '';
    emailRules = [
        (v: any) => !!v || 'E-mail is required',
        (v: any) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ];
    phone = '';
    phoneRules = [
        (v: any) => !!v || 'Phone is required',
        (v: any) =>
            /^\+?([0-9])\)?[-. ]?([0-9])[-. ]?([0-9])+/.test(v) ||
            'Phonel must be valid',
    ];

    message = '';
    messageRules = [
        (v: any) => !!v || 'Message is required',
        (v: any) =>
            (v && v.length <= 1000) || 'Name must be less than 1000 characters',
    ];
    checkbox = false;
    lazy = true;

    sendEmail(e: any) {
        if (this.$refs.form as Vue & { validate: () => boolean }) {
            emailjs
                .sendForm(
                    'service_jmvwcyf',
                    'template_3pf4y4f',
                    e.target,
                    'user_KyUtvrXZeyZ8YZpe9nY01',
                )
                .then(
                    (result) => {
                        this.snackbar = true;
                        console.log('SUCCESS!', result.status, result.text);
                    },
                    (error) => {
                        console.log('FAILED...', error);
                    },
                );
        }
    }
}














































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Header extends Vue {
    lMenu = [
        { title: 'Products', url: '#products', active: 'products--text' },
        {
            title: 'Solutions',
            url: '#solutions',
            active: 'solutions--text',
        },
        { title: 'Services', url: '#services', active: 'services--text' },
    ];
    rMenu = [
        { title: 'Partners', url: '#partners', active: 'partners--text' },
        { title: 'Sectors', url: '#sectors', active: 'sectors--text' },
        {
            title: 'Resources',
            url: '#resources',
            active: 'resources--text',
        },
    ];
}


































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Solutions extends Vue {
    solutions = [
        {
            title: 'Artificial Intelligence',
            icon: 'mdi-robot',
            text: 'Defining a New Generation of Government Technology.',
        },

        {
            title: 'GovTech',
            icon: 'mdi-apps',
            text: 'Defining a New Generation of Government Technology.',
        },
        {
            title: 'Smart Cities',
            icon: 'mdi-transit-connection-variant',
            text: 'Defining a New Generation of Government Technology.',
        },
        {
            title: 'Automation',
            icon: 'mdi-robot-industrial',
            text: 'Defining a New Generation of Government Technology.',
        },
        {
            title: 'Blockchain',
            icon: 'mdi-link-lock',
            text: 'Defining a New Generation of Government Technology.',
        },
        {
            title: 'Intelligent Solutions',
            icon: 'mdi-developer-board',
            text: 'Defining a New Generation of Government Technology.',
        },
        {
            title: 'Big Data',
            icon: 'mdi-database-search',
            text: 'Defining a New Generation of Government Technology.',
        },
        {
            title: 'Internet of Things',
            icon: 'mdi-memory',
            text: 'Defining a New Generation of Government Technology.',
        },
    ];

    mounted() {
        this.solutions.sort(function (a, b) {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
    }
}
